<template>
    <div id="insect-detail">
        <div>
            <p style="margin-left:36px;">请选择统计时段：
                <el-date-picker v-model="timePicker" type="daterange" align="right" unlink-panels range-separator="至"
                    start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" style="width: 40%;">
                </el-date-picker>
                <el-button type="primary" style="margin-left:20px;" @click="timeChanged">筛选</el-button>
                <el-button type="primary" @click="beginAnalysis">分析</el-button>
            </p>
        </div>
        <el-card style="width: 94%;margin-left: 3%;margin-bottom: 20px;">
            <div style="height: 80vh;">
                <el-col :span="6" v-for="item in partOfInsects" :key="item.cbdImgId">
                    <el-card shadow="hover" :body-style="{ padding: '0px',margin: '20px' }">
                        <img :src="'http://124.222.11.102:83/jsh-api' + item.rawImg" class="image">
                        <div style="padding: 2px;">
                            <div class="bottom clearfix">
                                <span style="font-size: 12px;">{{item.intableTime}}&nbsp;上报结果</span>
                                <p style="float:right;margin-bottom:0">
                                    <el-button type="text" class="button" @click="getDetailInsect(item)">详情分析
                                    </el-button>
                                </p>
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </div>
            <el-pagination layout="prev, pager, next" :total="total" :current-page="current" :page-size="8"
                @current-change="handleCurrentChange" style="margin: 20px;float: right;">
            </el-pagination>
        </el-card>
        <el-dialog title="详情分析" :visible.sync="dialogTableVisible">
            <div style="width:94%;margin-left:3%;">
                <p style="margin-top:0;font-size:18px;">判别前：</p>
                <img style="width:100%;" :src="'http://124.222.11.102:83/jsh-api' + selectedInsect.rawImg"
                    class="image">
                <p style="font-size:18px;">判别后：</p>
                <img v-if="selectedInsectTable.length > 0" style="width:100%;"
                    :src="'http://124.222.11.102:83/jsh-api' + selectedInsect.resultImg" class="image">
                <p v-else style="font-size: 14px;text-align: center;">彼时无虫害信息</p>
            </div>
            <el-table v-if="selectedInsectTable.length > 0" style="margin-top:10px;" :data="selectedInsectTable">
                <el-table-column property="name" label="昆虫种类"></el-table-column>
                <el-table-column property="count" label="昆虫数量"></el-table-column>
            </el-table>
        </el-dialog>
        <el-dialog title="虫情分析" :visible.sync="analysisBoxVisible" width="80%">
            <div id="analysis-div" style="width:100%;height:400px;margin-top:-20px;"></div>
        </el-dialog>
    </div>
</template>

<script>
    import * as echarts from 'echarts';

    export default {
        data() {
            return {
                pickerOptions: {
                    shortcuts: [{
                        text: '当天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                timePicker: {},
                queryTime: {
                    start: "",
                    end: "",
                },
                insects: [],
                partOfInsects: [],
                selectedInsect: {},
                selectedInsectTable: [],
                dialogTableVisible: false,
                analysisBoxVisible: false,
                total: 0,
                current: 1,
            }
        },
        created() {
            this.initInsects();
        },
        mounted() {

        },
        methods: {
            initInsects() {
                this.$webAxios.post("/jsh-api/census/insects2", this.queryTime).then(res => {
                    res = res.data;
                    this.insects = res.imgs;
                    this.partOfInsects = this.insects.slice(0, 8);
                    this.total = this.insects.length;
                })
            },
            timeChanged() {
                if (this.timePicker.length != 2) {
                    this.$message.warning("请确定统计时段！");
                    return;
                }
                this.queryTime.start = timeStampToFormatedDateStr(this.timePicker[0].getTime());
                this.queryTime.end = timeStampToFormatedDateStr(this.timePicker[1].getTime());
                this.$webAxios.post("/jsh-api/census/insects2", this.queryTime).then(res => {
                    res = res.data;
                    this.insects = res.imgs;
                    this.partOfInsects = this.insects.slice(0, 8);
                    this.total = this.insects.length;
                    this.current = 1;
                })
            },
            getDetailInsect(item) {
                this.dialogTableVisible = true;
                var that = this;
                this.selectedInsectTable = new Array();
                this.selectedInsect = item;
                var tmp = JSON.parse(item.judgeResult);
                Object.getOwnPropertyNames(tmp).forEach(function (key) {
                    var obj = new Object();
                    obj.name = key;
                    obj.count = tmp[key];
                    that.selectedInsectTable.push(obj);
                })
            },
            beginAnalysis() {
                if (this.timePicker.length != 2) {
                    this.$message.warning("请确定分析时段！");
                    return;
                }
                this.queryTime.start = timeStampToFormatedDateStr(this.timePicker[0].getTime());
                this.queryTime.end = timeStampToFormatedDateStr(this.timePicker[1].getTime());
                this.analysisBoxVisible = true;
                setTimeout(() => {
                    var chartDom = document.getElementById('analysis-div');
                    var myChart = echarts.init(chartDom);
                    var option;
                    var legendData, xAxisData, series = new Array();
                    this.$webAxios.post("/jsh-api/census/analysis2", this.queryTime).then(res => {
                        res = res.data;
                        legendData = res.name;
                        xAxisData = res.time;
                        for (var i = 0; i < res.values.length; i++) {
                            var obj = new Object();
                            obj.name = res.name[i];
                            obj.type = 'line';
                            obj.data = res.values[i];
                            series.push(obj);
                        }
                        option = {
                            tooltip: {
                                trigger: 'axis'
                            },
                            legend: {
                                data: legendData
                            },
                            grid: {
                                left: '3%',
                                right: '4%',
                                bottom: '3%',
                                containLabel: true
                            },
                            toolbox: {
                                feature: {
                                    saveAsImage: {}
                                }
                            },
                            xAxis: {
                                type: 'category',
                                boundaryGap: false,
                                data: xAxisData
                            },
                            yAxis: {
                                type: 'value'
                            },
                            series: series,
                            dataZoom: [{
                                type: 'inside',
                                show: true,
                                startValue: 0,
                                endValue: 5,
                                height: '15',
                                bottom: '3%',
                                zoomOnMouseWheel: true
                            }],
                        };
                        option && myChart.setOption(option);
                    })
                }, 10);
            },
            handleCurrentChange(page) {
                this.current = page;
                this.partOfInsects = this.insects.slice((page - 1) * 8, page * 8);
            },
        },
    }

    function timeStampToFormatedDateStr(timeStamp) {
        var time = new Date(timeStamp);
        var y = time.getFullYear();
        var m = time.getMonth() + 1;
        var d = time.getDate();
        var h = time.getHours();
        var mm = time.getMinutes();
        var s = time.getSeconds();
        return y + '-' + add0(m) + '-' + add0(d) + ' ' + add0(h) + ':' + add0(mm) + ':' + add0(s);
    }

    function add0(m) {
        return m < 10 ? '0' + m : m;
    }
</script>

<style>
    #insect-detail .time {
        font-size: 13px;
        color: #999;
    }

    #insect-detail .bottom {
        margin-top: 13px;
        line-height: 12px;
    }

    #insect-detail .button {
        padding: 0;
        float: right;
    }

    #insect-detail .image {
        width: 100%;
        display: block;
    }

    #insect-detail .clearfix:before,
    #insect-detail .clearfix:after {
        display: table;
        content: "";
    }

    #insect-detail .clearfix:after {
        clear: both
    }

    #insect-detail th,
    #insect-detail td {
        text-align: center !important;
    }
</style>